import * as React from 'react';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

const steps = [
  'Prepare',
  'Approve',
  'Send',
];
export default function ProgressBar() {
  return (
    <section className="w-[100vw] h-[110px] flex justify-center md:mt-[8vh] mt-[20px] items-center">
      <div className='border bg-white border-primary h-[12vh] md:w-[60vw] w-[90vw] flex justify-center items-center rounded-[12px]'>
      <Stepper activeStep={0}  alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel><div className='border border-primary text-primary font-bold md:mx-[96px] mx-[6vw] md:px-[22px] px-[8px]'>{label}</div></StepLabel>
          </Step>
        ))}
      </Stepper>
      </div>
    </section>
  );
}
