import React from "react";
import Header from "../components/Header";
import SendToken from "../components/SendToken";
import SupportedNetworks from "../components/SupportedNetworks";
import RecentTransction from "../components/RecentTransction";
import Carousel from "../components/Carasoul";
import Calculator from "../components/Calculator";
import Footer from "../components/Footer";

export default function Main() {
  return (
    <>
      <Header />
      <SendToken />
      <SupportedNetworks />
      <RecentTransction />
      <Carousel />
      <Calculator />
      <Footer />
    </>
  );
}
