import React from "react";

const HeaderCard = () => {
  return (
    <>
      <div className="container mx-auto my-16 md:my-0 md:mb-20 md:mt-[-75px]">
        <div className="grid grid-cols-2 md:grid-cols-4 md:gap-4 gap-4 capitalize lg:mx-0 mx-4 text-center ">
          <div className="max-w-[260px] h-[150px] bg-white min-h-full min-w-full shadow-card flex justify-center items-center flex-col rounded-[10px]">
            <h3 className="text-primary font-semibold font-sans text-[35px]">
              20k+
            </h3>
            <h5 className="text-primary font-normal font-sans text-[18px] lg:text-[24px]">
              Total Volume
            </h5>
          </div>
          <div className="max-w-[260px] h-[150px] bg-white min-h-full min-w-full shadow-card flex justify-center items-center flex-col rounded-[10px]">
            <h3 className="text-primary font-semibold font-sans text-[35px]">
              10k+
            </h3>
            <h5 className="text-primary font-normal font-sans lg:text-[24px] text-[18px] ">
              supported Network
            </h5>
          </div>
          <div className="max-w-[260px] h-[150px] bg-white min-h-full min-w-full shadow-card flex justify-center items-center flex-col rounded-[10px]">
            <h3 className="text-primary font-semibold font-sans text-[35px]">
              100k+
            </h3>
            <h5 className="text-primary font-normal font-sans lg:text-[24px] text-[18px] ">
              Total Transaction
            </h5>
          </div>
          <div className="max-w-[260px] h-[150px] bg-white min-h-full min-w-full shadow-card flex justify-center items-center flex-col rounded-[10px]">
            <h3 className="text-primary font-semibold font-sans text-[35px]">
              18k+
            </h3>
            <h5 className="text-primary font-normal font-sans lg:text-[24px] text-[18px] ">
              active users
            </h5>
          </div>
        </div>
      </div>
    </>
  );
};

export default HeaderCard;
