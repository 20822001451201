import React, { useState } from "react";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";

const Navbar = () => {
  const [toggle, setToggle] = useState<boolean>(false);
  return (
    <>
      <div className="container mx-auto lg:py-12 md:py-6 py-4 w-full px-4 lg:px-0 bg">
        <nav className="flex items-center justify-between">
          <div className="logo">
            <span
              className="font-semibold md:text-[30px] text-[24px] font-sans text-white"
            >
              Crypto Multisend
            </span>
          </div>
          {toggle ? (
            <AiOutlineClose
              onClick={() => setToggle(!toggle)}
              className="text-white text-xl lg:hidden block cursor-pointer fixed right-5"
            />
          ) : (
            <AiOutlineMenu
              onClick={() => setToggle(!toggle)}
              className="text-white text-xl lg:hidden block cursor-pointer fixed right-5 "
            />
          )}

          <ul className="hidden lg:flex items-center gap-20 font-normal text-white text-[20px] text-opacity-70 shadow-menu px-14 py-3 rounded-[10px]  transition-all">
            <li className="">
              <span>Home</span>
            </li>
            <li>
              <span>About</span>
            </li>
            <li>
              <span>Products</span>
            </li>
          </ul>
          <ul className="hidden lg:flex items-center gap-10 font-semibold text-[20px] text-center font-sans">
            <li className="text-white border border-white min-w-[170px] py-2 rounded-[10px]">
              <span>Refer and Earn</span>
            </li>
            <li className="text-primary bg-white min-w-[170px] py-2 rounded-[10px]">
              <span>Dapp</span>
            </li>
          </ul>
        </nav>
        <MobileView toggleBtn={toggle} />
      </div>
    </>
  );
};

export default Navbar;

type Props = {
  toggleBtn?: boolean;
};
const MobileView = (props: Props) => {
  // console.log(props.toggleBtn);

  return (
    <>
      <ul
        className={`lg:hidden w-[100vw] h-screen duration-500 text-primary fixed bg-white ${
          props.toggleBtn ? "right-[0%]" : "right-[-100%]"
        } top-[75px] md:top-[100px] transition-all text-center divide-y divide-slate-200 z-[100]`}
      >
        <li className="">
          <span>Home</span>
        </li>
        <li>
          <span>About</span>
        </li>
        <li>
          <span>Products</span>
        </li>
      </ul>
    </>
  );
};
