import React from "react";

const Banner = () => {
  return (
    <>
      <section className="relative">
        <div className="container mx-auto md:py-12 py-14 relative">
          <div className="flex items-center justify-center">
            <div className=" h-[2px] w-[60px] bg-[#D9D9D9] mr-4"></div>
            <div className=" h-8 w-8 border-2 border-[#D9D9D9] rounded-full"></div>
            <div className=" h-[2px] w-[60px] bg-[#D9D9D9] ml-4"></div>
          </div>
          <div className="h-[112px] w-[112px] rounded-full bg-circle opacity-[0.1] absolute left-[23%] top-[12%]"></div>
          <div className="py-6">
            <h1 className="text-center text-white capitalize font-pra text-[24px] md:text-[32px] lg:text-[45px] font-bold">
              Send, Secure & Simplify your <br /> Transactions to multiple
              wallet with Crypto Multisends
            </h1>
            <p className="text-center capitalize text-white font-pra md:text-[20px] text-[14px]  font-normal md:my-8 my-4">
              Send Your Crypto to Multiple Wallets in single Click Without Any
              Additional Premium Charge
            </p>
          </div>
          <div className="h-[80px] w-[80px] rounded-full bg-circle  absolute right-[19%] top-[59%] opacity-[0.1]"></div>
          <div className="mx-auto w-full flex justify-center">
            <span
              className="text-primary flex justify-center items-center bg-white min-w-[200px] md:min-w-[250px] md:max-w-[250px] md:h-[65px] h-[50px] py-2 rounded-[10px] text-center text-[18px] md:text-[22px] font-semibold font-sans"
            >
              Launch Dapp
            </span>
          </div>
        </div>
        <div className="h-[80px] w-[80px] rounded-full bg-circle absolute top-[50%] left-[-2.5%] opacity-[0.1]"></div>
      </section>
    </>
  );
};

export default Banner;
