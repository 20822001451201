import React, { useState } from "react";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import ReactModal from "react-modal";


const NavbarDapp = () => {
  const [toggle, setToggle] = useState<boolean>(false);
  const [modal] = useState<boolean>(false);
  const closeModal = async () => {
    // setmodal(!modal);
  }
  return (
    <>
    <ReactModal
      isOpen={modal}
      onRequestClose={closeModal}
      contentLabel="Modal"
      className='w-[20vw] mx-auto h-[60vh] bg-red-400'
      style={{
        overlay: {
          backgroundColor: 'transparent',
        },
        content: {
          /* Modal content styles */
        },
      }}
    >
      {/* Add your modal content here */}
      <h2>Modal Content</h2>
      <button onClick={closeModal}>Close Modal</button>
    </ReactModal>
      <div className=" bg-white flex justify-center items-center  py-4 w-full px-4 lg:px-0 bg">
        <nav className="flex  items-center w-full md:w-auto">
          <div className="logo">
            <span
              className="font-semibold md:text-[30px] text-[24px] font-sans text-primary"
            >
              Crypto Creator
            </span>
          </div>
          {/* <AiOutlineMenu className="text-white text-xl lg:hidden block cursor-pointer" /> */}
          {toggle ? (
            <AiOutlineClose
              onClick={() => setToggle(!toggle)}
              className="text-primary text-xl lg:hidden block cursor-pointer fixed right-5"
            />
          ) : (
            <AiOutlineMenu
              onClick={() => setToggle(!toggle)}
              className="text-primary text-xl lg:hidden block cursor-pointer fixed right-5"
            />
          )}

          <ul className="hidden   border mr-[8vw] ml-[8vw] border-primary lg:flex items-center gap-20 font-normal text-primary text-[20px] text-opacity-70 shadow-menu px-14 py-3 rounded-[10px]  transition-all">
            <li className="">
              <span>Home</span>
            </li>
            <li>
              <span >About</span>
            </li>
            <li>
              <span >Products</span>
            </li>
          </ul>
          <ul className="hidden lg:flex items-center gap-10 font-semibold text-[20px] text-center font-sans">
            <li onClick={closeModal} className="text-primary bg-white border border-primary min-w-[180px] py-2 rounded-[10px]">
              <span>Ethereum</span>
            </li>
            <li className="text-white bg-primary border-white border-[2px] min-w-[180px] py-2 rounded-[10px]">
              <span >Connect Wallet</span>
            </li>
          </ul>
        </nav>
        <MobileView  toggleBtn={toggle} />
      </div>
    </>
  );
};

export default NavbarDapp;

type Props = {
  toggleBtn?: boolean;
};
const MobileView = (props: Props) => {
  // console.log(props.toggleBtn);

  return (
    <>
      <ul
        className={`lg:hidden w-full h-screen duration-500 text-primary fixed bg-white ${
          props.toggleBtn ? "right-[0%]" : "right-[-100%]"
        } top-[75px] md:top-[100px] transition-all text-center divide-y divide-slate-200 z-[100]`}
      >
        <li className="">
          <span >Home</span>
        </li>
        <li>
          <span >About</span>
        </li>
        <li>
          <span>Testnet</span>
        </li>
        <li>
          <span >Connect Wallet</span>
        </li>
      </ul>
    </>
  );
};
