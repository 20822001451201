import React, { useRef } from "react";
import Slider from "react-slick";
import { TbCurrencyEthereum } from "react-icons/tb";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Left from "../assets/images/left.png";
import Right from "../assets/images/right.png";




const Carousel: React.FC = () => {
  const sliderRef = useRef<Slider>(null);

  const slideData = [
    { id: 1, title: "Slide 1" },
    { id: 2, title: "Slide 2" },
    { id: 3, title: "Slide 3" },
    { id: 4, title: "Slide 4" },
    { id: 5, title: "Slide 5" },
    { id: 6, title: "Slide 6" },
  ];

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 2000,
    centerMode: true,
    centerPadding: "0%",
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const handlePrevious = () => {
    sliderRef.current?.slickPrev();
  };

  const handleNext = () => {
    sliderRef.current?.slickNext();
  };

  return (
    <>
      <div className="cara flex items-center justify-center">
        <img src={Left} alt="not found" onClick={handlePrevious}></img>
        <div className="carousel-container">
          <Slider {...settings} ref={sliderRef}>
            {slideData.map((slide) => (
              <div className="carousel-item " key={slide.id}>
                <div className="test p-[10px]">
                  <div>
                    <div className="carasoul-item m-[auto]">
                      <span className="text-[14px] font-[regular]  mr-[100px]">
                        Number of Recipients
                      </span>
                      <span className=" text-[14px] font-semibold">20</span>
                    </div>
                    <div className="carasoul-item ">
                      <span className="text-[14px] font-[regular] mr-[156px]">Networks</span>
                      <span className=" text-[14px] font-semibold">Usdt</span>
                    </div>
                    <div className="carasoul-item flex justify-center items-center">
                      <span className="text-[14px] font-[regular]  mr-[134px]">Token</span>
                      <div className="border-[1px]  p-[6px] border-[#461AD7] w-[89px] h-[29px] rounded-md flex justify-center items-center">
                        <TbCurrencyEthereum className="text-[#461AD7] w-[16px] h-[16px]" />
                        <span className="text-[#461AD7] text-[14px] ">polygon</span>
                      </div>
                    </div>
                    <div className="carasoul-item">
                      <span className="text-[14px] font-[regular] mr-[158px] ">Amount</span>
                      <span className="text-[14px] font-semibold">$2,347</span>
                    </div>
                    <div className="carasoul-item">
                      <span className="text-[14px] font-[regular] mr-[48px] md:mr-[105px]">Txn Hash</span>
                      <a className="md:text-[12px] font-medium text-[#461AD7]" href="https://bscscan.com">
                        Show Transactions
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
        <img src={Right} alt="not found" onClick={handleNext}></img>
      </div>
    </>
  );
};

export default Carousel;
