import React from "react";
import NavbarDapp from "../components/NavbarDapp";
import ProgressBar from "../components/ProgressBar";
import TokenSender from "../components/TokenSender";
import Footer from "../components/Footer";

function Dapp() {
  return (
    <>
      <NavbarDapp />
      <ProgressBar />
      <TokenSender />
      <Footer />
    </>
  );
}

export default Dapp;
