import { CircularProgress } from '@mui/material'
import React, { useRef, useState } from 'react'
import { AiOutlineSearch } from 'react-icons/ai'
import { BiExpandAlt } from 'react-icons/bi'
import ReactModal from 'react-modal'
import { Tooltip as ReactTooltip } from "react-tooltip";


export default function TokenSender() {
  const [deflationary, setdeflationary] = useState(false)
  const [modal, setmodal] = useState<boolean>(false);
  const textRef: any = useRef(null);
  const textRef2: any = useRef(null);
  const [tempCounter, settempCounter] = useState(4)
  const [rows, setRows] = useState('1');
  const [modalView, setmodalView] = useState(true)
  const [selector, setselector] = useState(false)
  const [searchValue, setsearchValue] = useState('r')
  const [txtValue, setTxtValue] = useState('')
  const closeModal = async () => {
    setmodal(!modal);
    setmodalView(!modalView);
  }

  const lineCounter = (e: any) => {
    let temp = e;
    settempCounter(temp);
    if (tempCounter === temp) {
    } else {
      let t = '';
      let y = 0
      for (let i = 0; i < temp; i++) {
        y = y + 1
        t = t + y + '\n'
      }
      setRows(t)
    }
  }

  const divider = (e: any) => {
    setTxtValue(e);
    lineCounter(e.split("\n").length)
  }

  const scroll = () => {
    const scrollTop = textRef2.current.scrollTop;
    textRef.current.scrollTop = scrollTop;
  }


  return (
    <div className='w-[100vw] h-auto flex justify-center items-center'>
      <ReactModal
        isOpen={modal}
        onRequestClose={closeModal}
        contentLabel="Modal"
        className='mx-auto  bg-white'
        style={{
          overlay: {
            backgroundColor: 'transparent',
          },
          content: {
            /* Modal content styles */
          },
        }}
      >
        <div className='w-full h-[90vh] bg-white mt-[10vh] flex justify-center items-center'>
          <div className='border  border-primary flex justify-center items-center rounded-[10px] md:w-[96%] w-[90%] md:h-[92%] h-[92%] '>
            <div className='md:w-[8%] w-[14vw] h-full border-r border-primary p-1 flex justify-center items-center'>
              <textarea readOnly value={rows} className='h-full text-primary md:w-[60%] w-[60%] outline-none resize-none overflow-hidden' style={{ scrollbarWidth: 'none', msOverflowStyle: 'none' }}></textarea>
            </div>
            <div className='w-full h-full p-1'>
              <textarea onScroll={scroll} className='h-full text-primary w-full outline-none rounded-[10px] resize-none ' value={txtValue} onChange={(e) => { divider(e.target.value) }} ></textarea>
            </div>
          </div>
        </div>
      </ReactModal>
      {modalView ? (<div className='md:w-[60vw] w-[90vw] md:h-[54vh] h-[60vh] border border-primary bg-white shadow-2xl mt-[30px] rounded-[12px] py-[10px] md:py-[10px] md:p-[10px]'>
        <div className='flex justify-center items-center flex-col h-full'>
          <div className='md:w-[80%] w-[90%] bg-white'>
            <div className='flex md:flex-row flex-col justify-center items-center '>
              <div className='flex flex-col w-full'>
                <span className='text-primary w-[100%] font-bold mr-[31vw]'>Token Address</span>
                <div className='relative'>
                  <div className='flex justify-center w-full h-[3.18em] mr-[20px] rounded-[12px] items-center border-primary border'>
                    <AiOutlineSearch style={{ color: '#461ad7', fontSize: '28px', marginLeft: '10px' }} />
                    <input className='bg-white border-none outline-none w-full ml-[8px] mr-[20px] text-primary placeholder:text-primary' placeholder='Token Address Here' onChange={(e) => {
                      setsearchValue(e.target.value)
                    }}
                      value={searchValue}
                      onFocus={() => setselector(true)}
                      onBlur={() => setselector(false)} />
                    <div className='mr-[10px]'>
                      <CircularProgress style={{ color: '#461ad7' }} size={24}></CircularProgress>
                    </div>
                  </div>
                  {selector && searchValue.length === 0 ? <div className='absolute top-auto bg-white left-0 flex justify-center w-full h-[3.18em] mr-[20px] rounded-[12px] items-center border-primary border' onClick={() => {
                    setsearchValue('Native Token')
                    alert(searchValue);
                  }}>
                    <span>Native Token</span>
                  </div> : <></>}
                </div>
              </div>
              <div className='flex  flex-col items-start md:items-end w-full mt-[10px] md:mt-0'>
                <div className='flex  justify-center items-center' id="info">
                  <span className='text-primary font-bold mr-[8px]'>Deflationary</span>
                  <div className='bg-primary text-white rounded-full border font-[16px] h-[20px] w-[20px] flex items-center justify-center' >!</div>
                  <ReactTooltip
                    anchorId="info"
                    className='custom-tooltip'
                    place="top"
                    content="Please ignore this if your token isn't deflationary
                     Most tokens are not , so leave it as it is"
                  />
                </div>
                <div className={`md:w-[8.38em] md:h-[3.18em] w-[5.19em] h-[2.8em] rounded-[12px] transition-all border-primary  flex border-[2px] p-1 ${deflationary ? 'text-white shadow-card shadow-primary ' : 'text-gray-500 '}`} onClick={() => {
                  setdeflationary(!deflationary);
                }}>
                  <div className={` transition-all rounded-[8px]  w-[50%] ${deflationary ? 'ml-[50%] bg-[#240c7b]' : 'ml-0 bg-[#5e3ccb]'}`}></div>
                </div>
              </div>
            </div>
            <div className='flex w-[100%] mt-[18px] mb-[5px]'>
              <div className='flex w-full items-start justify-start'>
                <span className='text-primary md:text-[18px] text-[14px]'>List of Addresses in CSV</span>
              </div>
              <div className='w-full flex items-end justify-end'>
                <span className='text-primary text-[14px] md:text-[18px] font-bold'>Show Sample Csv</span>
              </div>
            </div>
          </div>
          <div className='border  border-primary rounded-br-[0px] flex justify-center items-center rounded-[10px] md:w-[80%] w-[90%] md:h-[40%] h-[30%] '>
            <div className='md:w-[8%] w-[14vw] h-full border-r border-primary p-1 flex justify-center items-center'>
              <textarea ref={textRef} readOnly value={rows} className='h-full text-primary md:w-[60%] w-[60%] outline-none resize-none overflow-hidden' style={{ scrollbarWidth: 'none', msOverflowStyle: 'none' }}></textarea>
            </div>
            <div className='w-full h-full p-1'>
              <textarea ref={textRef2} onScroll={scroll} className='h-full text-primary w-full outline-none rounded-[10px] resize-none ' value={txtValue} onChange={(e) => { divider(e.target.value) }} ></textarea>
            </div>
          </div>
          <div className='flex justify-end md:w-[80%] w-[90%] mb-[10px]'>
            <div className='border flex justify-center items-center border-t-[0px] border-primary'>
              <div className='px-[8px] py-[4px] text-primary'>
                <span>upload csv</span>
              </div>
              <div onClick={closeModal} className=' px-[8px] py-[4px] border-l border-primary'>
                <BiExpandAlt style={{ fontSize: '22px', color: '#461ad7' }}></BiExpandAlt>
              </div>
            </div>
          </div>
          <div className='w-[100%] flex justify-center items-center'>
            <button className='text-white w-[80%] h-[48px] font-bold rounded-[10px] bg-primary'>Connect Wallet</button>
          </div>
        </div>
      </div>) : (<></>)}
    </div>
  )
}
