import React from "react";

const RecentTransction = () => {
  

  return (
    <>
      <section className="py-12">
        <div className="conatiner mx-auto">
          <div className="max-w-screen-md mx-auto text-center">
            <h2 className="text-heading font-sans font-bold md:text-[35px] text-[28px]">
              Recent transactions
            </h2>
            
          </div>
        </div>
      </section>
    </>
  );
};

export default RecentTransction;


