import React, { useState } from "react";
import Ethereum from "../assets/images/ethereum.png";
import Gnosis from "../assets/images/gnosis.png";
import FantomOpera from "../assets/images/fantomOpera.png";
import Polygon from "../assets/images/polygon.png";
import Moonbeam from "../assets/images/moonbeam.png";
import Bitcoin from "../assets/images/bitcoin.png";
import Avalanche from "../assets/images/avalanche.png";
import Moonriver from "../assets/images/moonriver.png";
import Dogecoin from "../assets/images/dogecoin.png";
import Chainlink from "../assets/images/chainlink.png";


interface InetworksSupport {
  asset: any;
  title: string;
  category: string;
}

const SupportedNetworks = () => {
  const [networkSupports, setNetworkSupports] =
    useState<InetworksSupport[]>(supportNewtworkData);

  const filterMenu = (category: string) => {
    if (category === "all") {
      return setNetworkSupports(supportNewtworkData);
    }
    const updateFilterMenu = supportNewtworkData.filter(
      (curElem: InetworksSupport) => {
        return curElem.category === category;
      }
    );
    setNetworkSupports(updateFilterMenu);
  };


  return (
    <>
      <section className="md:pb-20">
        <div className="container mx-auto">
          <div className="w-full md:max-w-screen-md mx-auto text-center">
            <h2 className="text-heading font-sans font-bold md:text-[35px] text-[28px]">
              Supported networks
            </h2>
           
          </div>
          <div className="md:max-w-[900px] rounded-[10px] my-10 mx-auto bg-primary bg-opacity-20 text-primary py-2 px-4">
            <ul className="flex md:justify-between items-center  capitalize">
              <li
                className={`md:text-2xl font-normal font-sans  cursor-pointer bg-primary text-white py-4 px-2 md:px-4 rounded-[8px]`}
                onClick={() => filterMenu("all")}
              >
                All
              </li>
              <li
                className="md:text-2xl font-normal font-sans  cursor-pointer"
                onClick={() => filterMenu("classic multisender")}
              >
                classic multisender
              </li>
              <li
                className="md:text-2xl font-normal font-sans  cursor-pointer"
                onClick={() => filterMenu("classic")}
              >
                NFT multisender
              </li>
              <li
                className="md:text-2xl font-normal font-sans  cursor-pointer"
                onClick={() => filterMenu("classic multisender")}
              >
                massdrop multisender
              </li>
            </ul>
          </div>
          <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-5 mt-14 bg-white border lg:border-primary rounded-[20px] py-6 mx-auto">
            {networkSupports.map((items: InetworksSupport, index: number) => {
              const { asset, title,  } = items;
              return (
                <div
                  className="h-[165px] w-[165px] shadow-tokenCard rounded-[10px] flex flex-col justify-center items-center sm:m-8 md:mx-auto mx-auto my-3 p-1"
                  key={index}
                >
                  <img src={asset} height={70} width={70} alt={title} />
                  <h3 className="font-sans text-2xl text-primary mt-3">
                    {title}
                  </h3>
                </div>
              );
            })}
          </div>
        </div>
      </section>  
    </>
  );
};

export default SupportedNetworks;

const supportNewtworkData: InetworksSupport[] = [
  {
    asset: Ethereum,
    title: "Ethereum",
    category: "classic multisender",
  },
  {
    asset: Gnosis,
    title: "Gnosis",
    category: "classic",
  },
  {
    asset: FantomOpera,
    title: "Fantom Opera",
    category: "classic multisender",
  },
  {
    asset: Polygon,
    title: "Polygon",
    category: "classic",
  },
  {
    asset: Moonbeam,
    title: "Moonbeam",
    category: "classic multisender",
  },
  {
    asset: Bitcoin,
    title: "Bitcoin",
    category: "classic",
  },
  {
    asset: Avalanche,
    title: "Avalanche",
    category: "classic",
  },
  {
    asset: Moonriver,
    title: "Moonriver",
    category: "classic",
  },
  {
    asset: Dogecoin,
    title: "Dogecoin",
    category: "Dogecoin",
  },
  {
    asset: Chainlink,
    title: "Chainlink",
    category: "classic multisender",
  },
];
