import React from "react";
import Banner from "./Banner";
import HeaderCard from "./HeaderCard";
import Navbar from "./Navbar";

const Header = () => {
  return (
    <>
      <section className="bg-primary pb-[100px]">
        <Navbar />
        <Banner />
      </section>
      <HeaderCard />
    </>
  );
};

export default Header;
