import React from 'react';
import { Routes, Route} from "react-router-dom";
import './App.css';
import Main from './pages/Main';
import Dapp from './pages/Dapp';

function App() {
  return (
    <div>
    <Routes>
      <Route path="/" element={<Main />}>
        <Route index element={<Main />} />
        
      </Route>
      <Route path="/dapp" element={<Dapp />}>
        <Route index element={<Dapp />} />
      </Route>
    </Routes>
  </div>
  );
}

export default App;
