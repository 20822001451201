import facebookIcon from "../assets/images/facebookIcon.png";
  import telegramIcon from "../assets/images/telegramIcon.png";
  import twiterIcon from "../assets/images/twiter.png";
  import phoneIcon from "../assets/images/phoneIcon.png";
  import whatsappIcon from "../assets/images/whatsappIcon.png";
  import gmailIcon from "../assets/images/gmailIcon.png";
  import locationIcon from "../assets/images/locationIcon.png";
  import githubIcon from "../assets/images/github.png";

  const Footer = () => {
    return (
      <div className="bg-primary py-5 mt-[75px]">
        <div className="container mx-auto py-6 ">
          <div className="md:grid md:grid-cols-2 flex justify-center items-center">
            <div className="footer">
              <h5 className="font-semibold md:text-[35px] text-white font-sans">
                Subscribe for updates
              </h5>
              <form className="flex flex-col py-9 md:flex-row max-w-[390px]">
                <input
                  placeholder="Enter your email..."
                  required
                  type="text"
                  className="flex-grow w-full h-14 px-4 mb-3 transition duration-200 text-white bg-primary border border-white rounded-l-[10px] placeholder-gray-100 shadow-sm appearance-none md:mb-0 focus:border-deep-purple-accent-400 focus:outline-none focus:shadow-outline"
                />
                <button
                  type="submit"
                  className="inline-flex items-center justify-center h-14 w-32 font-sans md:text-[16px] px-6 font-medium tracking-wide text-primary transition duration-200 rounded-r-lg shadow-md bg-white hover:bg-deep-purple-accent-700 focus:shadow-outline focus:outline-none"
                >
                  Subscribe
                </button>
              </form>
              <div className="grid grid-cols-2 gap-4">
                <div className="">
                  <h5 className="font-semibold md:text-[35px] text-white font-sans">
                    know more
                  </h5>
                  <ul className="py-6">
                    <li className="pb-4">
                      <span
                       
                        className="text-white font-sans md:text-[20px] block "
                      >
                        About Us
                      </span>
                    </li>
                    <li className="pb-4">
                      <span
                      
                        className="text-white font-sans md:text-[20px] block"
                      >
                        Our Blogs
                      </span>
                    </li>
                    <li className="pb-4">
                      <span
                        
                        className="text-white font-sans md:text-[20px] block"
                      >
                        Privacy Policy
                      </span>
                    </li>
                    <li className="pb-4">
                      <span
                       
                        className="text-white font-sans md:text-[20px] block"
                      >
                        Terms & Conditions
                      </span>
                    </li>
                  </ul>
                </div>
                <div className="">
                  <h5 className="font-semibold md:text-[35px] text-white font-sans block">
                    Other services
                  </h5>
                  <ul className="py-6">
                    <li className="pb-4">
                      <span
                       
                        className="text-white font-sans md:text-[20px] block "
                      >
                        Token Creator
                      </span>
                    </li>
                    <li className="pb-4">
                      <span
                       
                        className="text-white font-sans md:text-[20px] block"
                      >
                        Airdrop
                      </span>
                    </li>
                    <li className="pb-4">
                      <span
                      
                        className="text-white font-sans md:text-[20px] block"
                      >
                        Ico Launchpad
                      </span>
                    </li>
                    <li className="pb-4">
                      <span
                       
                        className="text-white font-sans md:text-[20px] block"
                      >
                        WEB3 Devlopment
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="md:ml-auto">
              <h5 className="font-semibold md:text-[35px] text-white font-sans">
                Reach Us At
              </h5>
              <ul className="flex justify-between align-top py-9 max-w-[390px]">
                <li>
                  <a href="https://github.com/web5lab">
                    <img
                      height={40}
                      width={40}
                      src={githubIcon}
                      alt="githubIcon"
                    />
                  </a>
                </li>
                <li>
                  <a href="https://www.spanedin.com/company/web5lab/">
                    <img
                      height={40}
                      width={40}
                      src={facebookIcon}
                      alt="facebookicon"
                    />
                  </a>
                </li>
                <li>
                  <a href="https://t.me/cryptomultisend_support">
                    <img
                      height={40}
                      width={40}
                      src={telegramIcon}
                      alt="facebookicon"
                    />
                  </a>
                </li>
                <li>
                  <a href="https://twitter.com/web5lab">
                    <img
                      height={40}
                      width={40}
                      src={twiterIcon}
                      alt="facebookicon"
                    />
                  </a>
                </li>
              </ul>
              <div className="contact">
                <ul className="pt-4">
                  <li className="flex pb-6 align-middle">
                    <span className="block">
                      <img
                        height={26}
                        width={26}
                        src={phoneIcon}
                        alt="facebookicon"
                      />
                    </span>
                    <span
                      
                      className="ml-4 md:text-[20px] font-sans font-normal text-white"
                    >
                      +91 9835302415
                    </span>
                  </li>
                  <li className="flex align-middle text-white pb-6">
                    <span className="block">
                      <img
                        height={26}
                        width={26}
                        src={whatsappIcon}
                        alt="facebookicon"
                      />
                    </span>
                    <a
                      href="https://api.whatsapp.com/send?phone=9835302415"
                      className="ml-4 md:text-[20px] font-sans font-normal text-white"
                    >
                      +91-9835302415
                    </a>
                  </li>
                  <li className="flex align-middle text-white pb-6">
                    <span className="block mt-1">
                      <img
                        height={26}
                        width={26}
                        src={gmailIcon}
                        alt="facebookicon"
                      />
                    </span>
                    <a
                      href="mailto: mail@trainticket.in"
                      className="ml-4 md:text-[20px] font-sans font-normal text-white"
                    >
                      hello@nxtmeta.com
                    </a>
                  </li>
                  <li className="flex align-middle text-white">
                    <span className="block">
                      <img
                        height={26}
                        width={26}
                        src={locationIcon}
                        alt="facebookicon"
                      />
                    </span>
                    <span
                      className="ml-4 md:text-[20px] font-sans font-normal text-white"
                    >
                    india utter pardesh
                      <br /> Noida sector 37 
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="pt-5 border-t border-white border-opacity-50 text-center">
          <p className="text-white font-sans font-normal text-[14px] md:text-[18px]">
            <span>made with ❤️ in india by nxtmeta </span>
          </p>
        </div>
      </div>
    );
  };

  export default Footer;
