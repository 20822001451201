import React, { useRef, useState } from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Left from "../assets/images/leftNetwork.png";
import Right from "../assets/images/rightNetwork.png";

export default function Calculator() {
  const sliderRef = useRef<Slider>(null);
  const [nav, setnav] = useState(true);
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 2000,
    centerMode: true,
    centerPadding: "0%",
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const calculate = () => {
    setnav(!nav)
  }

  const handlePrevious = () => {
    sliderRef.current?.slickPrev();
  };

  const handleNext = () => {
    sliderRef.current?.slickNext();
  };

  return (
    <div className='flex justify-center flex-col items-center w-[100vw]  mt-[35px]'>
      <h1 className='text-[35px]'>Calculator</h1>
      <div className='md:w-[900px] w-[85vw] h-[80px] bg-[#e8e3f7] rounded-[10px] mt-[35px] flex justify-center items-center'>
        <button className='md:w-[269px] w-[70vw] h-[60px] bg-[#461AD7] rounded-[8px] text-[22px] text-[#FFFFFF]'>Crypto Multisend</button>
      </div>
      <div className='flex justify-center  items-center w-[90vw] md:w-[66vw] p-[10px] flex-col rounded-[20px] border-[1px] border-[#461AD7]  bg-[#FFFFFF] mt-[55px]'>
        <div className='md:text-[24px] text-[18px] w-[90%] flex justify-start font-semibold'>
          <span>Choose Network</span></div>
        <div className=' flex justify-center items-center mt-[20px] mb-[30px]'>
          <img src={Left} alt='not found' onClick={handlePrevious}></img>
          <Slider {...settings} className=' w-[55vw]' ref={sliderRef}>
            <div className='flex network_selector justify-center items-center'>
              <button className='w-[80%] text-[18px] m-auto h-[100%] md:pt-[10px] md:pb-[10px] rounded-[10px] border-[1px] border-[#461AD7]'>polygon</button>
            </div>
            <div className='flex network_selector justify-center items-center'>
              <button className='w-[80%] text-[18px] m-auto h-[100%] md:pt-[10px] md:pb-[10px] rounded-[10px] border-[1px] border-[#461AD7]'>polygon</button>
            </div>
            <div className='flex network_selector justify-center items-center'>
              <button className='w-[80%] text-[18px] m-auto h-[100%] md:pt-[10px] md:pb-[10px] rounded-[10px] border-[1px] border-[#461AD7]'>polygon</button>
            </div>
            <div className='flex network_selector justify-center items-center'>
              <button className='w-[80%] text-[18px] m-auto h-[100%] md:pt-[10px] md:pb-[10px] rounded-[10px] border-[1px] border-[#461AD7]'>polygon</button>
            </div>
            <div className='flex network_selector justify-center items-center'>
              <button className='w-[80%] text-[18px] m-auto h-[100%] md:pt-[10px] md:pb-[10px] rounded-[10px] border-[1px] border-[#461AD7]'>polygon</button>
            </div>
            <div className='flex network_selector justify-center items-center'>
              <button className='w-[80%] text-[18px] m-auto h-[100%] md:pt-[10px] md:pb-[10px] rounded-[10px] border-[1px] border-[#461AD7]'>polygon</button>
            </div>
            <div className='flex network_selector justify-center items-center'>
              <button className='w-[80%] text-[18px] m-auto h-[100%] md:pt-[10px] md:pb-[10px] rounded-[10px] border-[1px] border-[#461AD7]'>polygon</button>
            </div>
            <div className='flex network_selector justify-center items-center'>
              <button className='w-[80%] text-[18px] m-auto h-[100%] md:pt-[10px] md:pb-[10px] rounded-[10px] border-[1px] border-[#461AD7]'>polygon</button>
            </div>
          </Slider>
          <img src={Right} alt='not found' onClick={handleNext}></img>
        </div>
        <div className='mb-[20px] flex justify-start md:w-[840px] md:text-[24px] text-[20px] font-semibold'>
          <span>Select token</span>
        </div>
        <div><select className='md:w-[840px] w-[50vw] h-[55px] rounded-[10px] text-[20px] text-[#461AD7] border-[1px] border-[#461AD7] mb-[30px]' id='currency'>
          <option>Ethereum</option>
          <option>Erc20</option>
        </select></div>
        <div>
          <span></span>
          <span></span>
        </div>
        <div className='flex w-full justify-center items-center flex-col md:flex-row  md:w-[840px] md:text-[24px] text-[20px] font-semibold'>
          <div className='flex flex-col justify-center items-center'>
            <span className=' mr-auto mb-2'>Number of recipients</span>
            <input className='md:w-[370px] pl-[20px] h-[55px] rounded-[6px] border-[1px] text-primary border-[#461AD7] mb-[10px] md:mr-[100px]' type='number'></input>
          </div>
          <div className='flex flex-col justify-center items-center'>
            <span className='mr-auto mb-2'>Total tokens amount</span>
            <input className='md:w-[370px] pl-[20px] text-primary h-[55px] rounded-[6px] border-[1px] border-[#461AD7]' type='number'></input>
          </div>
        </div>
        <div><button className='md:w-[370px] w-[60vw] h-[60px] bg-[#461AD7] text-[22px] font-medium text-[#FFFFFF] rounded-[10px] mt-[50px]' onClick={calculate}>Calculate</button></div>
        {nav ? <div className='flex justify-center flex-col items-center text-white w-[90%] mt-[20px] border h-[20vh] bg-primary  rounded-2xl'>
          <span>sender fee</span>
          <span className='text-[36px]'>2.7056 ETH</span>
        </div> : <div></div>}
      </div>
    </div>
  )
}
