
import React from "react";
import Approve from "../assets/images/approve.png";
import Prepares from "../assets/images/prepare.png";
import Send from "../assets/images/send.png";
import VectorDown from "../assets/images/Vector-down.png";
import VectorUp from "../assets/images/Vector-up.png";

const SendToken = () => {
  return (
    <>
      <section className="pb-20">
        <div className="container mx-auto">
          <div className="max-w-screen-md mx-auto text-center">
            <h2 className="text-heading font-sans font-bold md:text-[35px] text-[28px]">
              How to Send Token
            </h2>
            <p className="text-heading font-sans font-normal md:text-[20px] text-[14px] text-opacity-70 my-1">
              You Can Easily Send Crypto or Token to By Following These Three
              Simple Steps
            </p>
          </div>
          <div className="grid grid-cols-3 mt-20">
            <div className="mx-auto relative">
              <img src={Prepares} height={170} width={320} alt="prepares" />
              <img
                src={VectorDown}
                height={20}
                width={70}
                alt="prepares"
                className="absolute top-[22%] right-[-40%] md:top-[30%] md:right-[-30%] w-12 md:w-auto"
              />
            </div>
            <div className="mt-40 mx-auto">
              <img src={Approve} height={170} width={320} alt="aprove" />
            </div>
            <div className="mx-auto relative">
              <img src={Send} height={170} width={320} alt="Send" />
              <img
                src={VectorUp}
                height={20}
                width={70}
                alt="VectorUp"
                className="absolute left-[-10%] lg:bottop-[30%] lg:left-[-30%] md:left-[-0%] w-12 md:w-auto"
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SendToken;
